
<template>
    <div class="index">
        <nav-component></nav-component>
        <div class="banner" v-bind:style="{'backgroundImage':'url('+ banner +')'}"></div>
        <div class="margin-center main ">
            <div class="center">
                <el-form :model="data" :rules="rules" ref="form" >
                    <el-form-item prop="set_meal" class="margin">
                        <div class="name">套餐选择<div class="index-box">1.</div></div>
                        <el-select class="input" v-model="data.set_meal"  placeholder="请选择套餐">
                            <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="city_at" class="margin">
                        <div class="name">所在城市<div class="index-box">2.</div></div>
                        <el-select class="input" v-model="data.city_at"  placeholder="请选择城市">
                            <el-option
                            v-for="item in city"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="address" class="margin">
                        <div class="name">具体地址<div class="index-box">3.</div></div>
                        <el-form-item prop="passcode" class="margin email">
                            <div class="font email_no">邮编</div>
                            <el-input class="input" v-model="data.passcode" placeholder="请填写邮编"></el-input>
                        </el-form-item>
                        <div class="font">房屋位置（门牌号+街道）</div>
                        <el-input class="input" v-model="data.address" placeholder="请填写房屋位置"></el-input>
                    </el-form-item>
                    <el-form-item prop="occupancy" class="margin">
                        <div class="name">入住合同中标注入住人数<div class="index-box">4.</div></div> 
                        <el-input class="input" v-model="data.occupancy" placeholder="请填写入住人数"></el-input>
                    </el-form-item>
                    <el-form-item prop="start_date" class="margin date">
                        <div class="name">租房合同开始日期<div class="index-box">5.</div></div>
                        <el-date-picker
                        v-model="data.start_date"
                        popper-class="popper-class"
                        class="input"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="over_date" class="margin date">
                        <div class="name">租房合同退租日期<div class="index-box">6.</div></div>
                        <el-date-picker
                        v-model="data.over_date"
                         popper-class="popper-class"
                        class="input"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="layout" class="margin">
                        <div class="name">房屋格局（几室几厅）<div class="index-box">7.</div></div>
                        <el-input class="input" v-model="data.layout" placeholder="请填写房屋格局"></el-input>
                    </el-form-item>
                    <el-form-item prop="is_uz" class="margin">
                        <div class="name">租房服务公司是游梓<div class="index-box">8.</div></div>
                        <el-radio-group v-model="data.is_uz" class="input radio" @change="showConsultant">
                            <el-radio  label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="data.is_uz == '1'"  class="margin">
                        <div class="name">专属顾问<div class="index-box">9.</div></div>
                        <el-select class="input choose" v-model="data.ex_consultant"  placeholder="请选择专属顾问">
                            <el-option
                            v-for="item in consultant"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="nickname" class="margin">
                        <div class="name">您的姓名<div class="index-box">10.</div></div>
                        <el-input class="input" v-model="data.nickname" placeholder="请填写您的姓名"></el-input>
                    </el-form-item>
                    <el-form-item prop="mobile" class="margin">
                        <div class="name">请输入您的手机号<div class="index-box">11.</div></div>
                        <el-input class="input" v-model="data.mobile" placeholder="请填写您的手机号"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" class="margin">
                        <div class="name">邮箱<div class="index-box">12.</div></div>
                        <el-input class="input" v-model="data.email" placeholder="请填写您的邮箱"></el-input>
                    </el-form-item>
                </el-form>
                <div class="tips">* 游梓公寓官方合作团队，如需要此项服务请提前联系游梓客服为您具体安排</div>
                <div class="button" @click="submitForm('form')" v-bind:style="{'backgroundImage':'url('+ button_bg +')'}">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import banner from '@assets/image/banner.jpg'
import button_bg from '@assets/image/button-bg.png'
import NavComponent from '@components/NavComponent'

export default {
    name: 'Bills',
    components:{
        NavComponent
    },
    data(){
        return {
            banner,button_bg,
            typeList:[],
            consultant:[],
            city:[],
            data:{
                set_meal:'',
                city_at:'',
                passcode:'',
                address:'',
                occupancy:'',
                start_date:'',
                over_date:'',
                layout:'',
                is_uz:'',
                ex_consultant:'',
                nickname:'',
                mobile:'',
                email:''
            },
            rules:{
                set_meal:[{ required: true,message: '请选择您的套餐', trigger: 'change'}],
                city_at:[{ required: true,message: '请选择您所在城市', trigger: 'change'}],
                passcode:[{ required: true,message: '请填写您的邮编', trigger: 'blur'}],
                address:[{ required: true,message: '请填写您的详细地址', trigger: 'blur'}],
                occupancy:[{ required: true,message: '请填写入住人数', trigger: 'blur'}],
                start_date:[{ required: true,message: '请填写租房日期', trigger: 'change'}],
                over_date:[{ required: true,message: '请填写退租日期', trigger: 'change'}],
                layout:[{ required: true,message: '请填写房间格局',  trigger: 'blur' }],
                is_uz:[{ required: true,message: '请填写是否来自游梓', trigger: 'change'}],
                nickname:[{required: true,message: '请填写姓名', trigger: 'blur'}],
                mobile:[{required: true,message: '请填写手机号', trigger: 'blur'}],
                email:[{required: true,message: '请填写邮箱', trigger: 'blur'}],
            }
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        submitForm(formName) {
            let that = this
            if(this.data.is_uz == '1' && !this.data.ex_consultant){
                that.$message({
                    message: '未选择顾问',
                    type: 'warning'
                });
                return;
            }
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.axios.post(this.url+'/api/bill_server/submit',that.data).then(function(res){
                    if(res.data.code == 1){
                        that.$alert('您已成功提交bills申请表单', '提交成功', {
                        confirmButtonText: '确定',
                            callback: ()=> {
                                that.$refs.form.resetFields();
                            }
                        });
                    }else{
                        that.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                }).catch(function() {
                });
            } else {
                that.$message({
                    message: '内容未填写完整，请补充',
                    type: 'warning'
                });
                return false;
            }
            });
        },
        getDetail() {
            let that = this
            this.axios.get(this.url+'/api/bill_server/setting').then(function(res){
                console.log(res.data)
                that.typeList = res.data.data.set_meal;
                that.consultant = res.data.data.consultant
                that.city = res.data.data.city
            }).catch(function() {
            });
        },
        showConsultant(val){
            console.log(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.banner{
    width: 100%;
    height: 14.6rem;
    background-clip:content-box;
    background-position: center;
    background-repeat:no-repeat ;
    background-size:cover;
}
.main{
    padding: 2.5rem 0;
}
.center{
    width: 70%;
    margin: 0 auto;
    text-align: left;
    .input{
        display: block;
    }
    .margin{
        margin-bottom: 1.4rem;
        .radio{
            display: flex;         
            margin:1rem
        }
    }
    .email_no{
        width: 16.1rem;
    }
    .choose{
        width: 17rem;
    }
    .name{
        width: auto;
        display: inline-block;
        font-family: 'fzcy';
        font-size: 1.2rem;
        line-height: 2rem;
        position: relative;
        padding: 0 1.6rem 0 4rem;
        color: #3c200d;
        box-sizing: border-box;
        margin-bottom: 1rem;
        border: solid 0.15rem #562f0f;
        background-color: #fdd343;
    }
    .index-box{
        width: 3.65rem;
        height: 2.55rem;
        position: absolute;
        color: #fff;
        font-family: 'fzcy';
        font-size: 1.6rem;
        line-height: 2.6rem;
        text-align: center;
        left: -0.15rem;
        top: -0.45rem;
        z-index: 99;
        background:url('~@assets/image/index-icon-bg.png') no-repeat center;
        background-size:3.65rem 2.55rem;
    }
    .tips{
        font-family: 'fzcy';
        font-size: 0.9rem;
        line-height: 1.2rem;
        margin-top: 3.3rem;
        letter-spacing: 0.03rem;
        color: #ef9547;
    }
    .font{
        color: #3c200d;
        font-size: 1.2rem;
        font-family: 'fzcy';
        text-indent: 1.2rem;
    }
}
.button{
    width: 19.15rem;
    height: 3.15rem;
    text-align: center;
    font-family: 'fzcy';
    font-size: 1.6rem;
    color: #3c200d;
    line-height: 3.15rem;
    margin: 6rem auto;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (max-width: 1024px){
    .banner{
        height: 8rem;
        margin-top: 4.5rem;
    }
    .main{
        width: 100%;
    }
    .center{
        width: 92%;
    }
}
</style>
<style lang="scss">
.margin .el-input__inner{
    border:0.15rem solid #562f0f;
    border-radius: 1rem;
    height: 3rem;
    font-size: 0.9rem;
}
.radio .el-radio__label{
    font-family: 'fzcy';
    font-size: 1.2rem;
    color: #3c200d;
}
.radio .el-radio{
    display: flex;
    align-items: center;
}
.radio .el-radio__inner{
    width: 1.2rem;
    height: 1.2rem;
}
.email .el-form-item__content{
    display: flex;
    align-items: center;
}
.email .el-input__inner{
    width: 30rem;
}
.date .el-input__inner{
    width: 24rem;
}
@media only screen and (max-width: 1024px){
    .email .el-form-item__content{
        display: block;
    }
    .email .el-input__inner{
        width: 100%;
    }   
}
</style>